.BondsManView .NavigationBar,
.AdminIndex .NavigationBar {
    width: 17rem;
}

.BondsManView .Views,
.AdminIndex .Views {
    margin-left: 17rem;
}

/* Navigation bar */
.NavigationBar {
    border-radius: 0;
    padding: 1.5rem 0 0.625rem;
    transition: all 0.25s ease;
    z-index: 99;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.NavigationBar .NavigationLink {
    align-items: center;
    color: inherit;
    height: 3.75rem;
    line-height: normal;
}

.NavigationBar .NavIconBG {
    align-items: center;
    background: transparent;
    border-radius: 0.375rem;
    display: flex;
    height: 2rem;
    justify-content: center;
    width: 2rem;
    padding: 0.313rem;
}

.NavigationBar .NavigationLink .labelContainer {
    font-size: 1rem;
    margin-left: 0.625rem;
    font-weight: 600;
}

.NavigationLink.active .NavIconBG svg [fill="black"],
.NavigationLink.active .NavIconBG svg [fill="#030104"] {
    fill: #3aa61c;
}

.NavigationLink.active .NavIconBG svg [stroke="black"],
.NavigationLink.active .NavIconBG svg [stroke="#030104"] {
    stroke: #3aa61c;
}

.NavigationLink.active .NavIconBG svg [stroke="white"],
.NavigationLink.active .NavIconBG svg [fill="white"] {
    fill: transparent;
    stroke: transparent;
}

.NavigationBar .NavigationLink.active .labelContainer {
    color: #3aa61c;
}

.NavigationBar .NavigationLink.active {
    background-color: rgba(58, 166, 28, 0.1);
}

.NavigationBar .NavigationLink.active:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: #3aa61c;
    right: 0;
}

.NavigationBar .lengthFor {
    min-height: 1.75rem;
    width: 2.313rem;
    background-color: var(--primary);
    text-align: center;
    border-radius: 14px;
    line-height: 1.75rem;
}

@media screen and (min-width: 991px) {
    .BondsManView .NavigationBar,
    .AdminIndex .NavigationBar {
        top: 5.625rem;
    }
}

@media screen and (max-width: 991px) {
    .BondsManView .NavigationBar,
    .AdminIndex .NavigationBar {
        display: none;
    }

    .BondsManView .container-fluid.Views,
    .AdminIndex .container-fluid.Views {
        margin-left: 0 !important;
    }
}

/* UnderReview */
.UnderReview .under-review-avatar {
    width: 6.875rem;
}

.rejectRequestIcon svg {
    width: 5rem;
}

@media (max-width: 767.98px) {
    .rejectRequestIcon svg {
        width: 4rem;
    }
}

/* All Bail */
.totalAmount {
    background: rgba(241, 109, 61, 0.1);
}

.totalAmount span {
    color: rgba(241, 109, 61, 1);
}

.allBailAvatar {
    width: 3.375rem;
}

.AllBail .antdSelect {
    min-width: 10rem;
}

@media (max-width: 575.98px) {
    .CaseTypeCard .allBailCard .f16 {
        font-size: 1rem;
    }
}

/* All Bail Detail - Pages */
.bail-detail-user-avatar > img {
    height: 8.75rem;
}

@media (max-width: 575.98px) {
    .bail-detail-user-avatar > img {
        height: 5.75rem;
    }
}

/* Secure Blur */
.filter-blur-5 {
    filter: blur(7px);
}

/* Place Bid */
.antdModalTable,
.antdModalTable *,
.ant-message,
.ant-message * {
    font-family: "Open Sans" !important;
}

.antdModalTable.ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #383838 !important;
    color: #fff !important;
}

.Service > div {
    flex: 1 0 0;
}

.BidModal .ant-modal-footer {
    display: block;
    border-radius: 0 0 8px 8px;
}

.antdModal.BidModal .ant-modal-content {
    padding: 0;
    max-height: min(90vh, 700px);
}

.antdModal.BidModal .ant-modal-body {
    padding: 20px 1rem 0rem 1rem;
}

.antdModal .footerPlaceBid {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: 0 0 8px 8px;
}

.antdModalTable .editable-row {
    font-size: 1rem;
}

.antdModalTable .deleteService svg,
.antdModalTable .editService svg {
    width: 1.625rem;
    height: 1.625rem;
}

.antdModalTable .ant-input,
.antdModalTable .ant-input-number-input-wrap input {
    height: 2.875rem;
}

.antdModalTable .ant-input::placeholder,
.antdModalTable .ant-input-number-input-wrap input::placeholder,
.antdModalTable .ant-input,
.antdModalTable .ant-input-number-input-wrap input {
    font-size: 1rem !important;
}

.antdModalTable .addService svg {
    height: 2.875rem;
}

.antdModalTable .ant-form-item .ant-form-item-control-input {
    min-height: auto;
}

.acceptBidButton .ant-spin .ant-spin-dot,
.changeSpinFlex .ant-spin .ant-spin-dot {
    display: inline-flex;
}

.changeSpinFlex .ant-spin .ant-spin-dot.me-0 {
    margin-right: 0 !important ;
}

.antdModalTable .ant-table-footer {
    padding: 0;
}

.changeSpinFlex.donwloadContract {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
}

.changeSpinFlex.donwloadContract svg {
    width: 1rem;
    height: 1rem;
    flex: 0 0 1rem;
}

.changeSpinFlex.donwloadContract .ant-spin .ant-spin-dot {
    font-size: 12px !important;
    width: auto;
}

.changeSpinFlex.donwloadContract .ant-spin-spinning {
    display: grid;
    place-items: center;
}

/* Service List */
.servicePercentage input {
    height: 2.125rem;
    min-height: 2.125rem !important;
    border-radius: 0.25rem !important;
    padding-left: 0.5rem;
}

.servicePercentage input::placeholder {
    font-size: 0.75rem !important;
}

.servicePercentage .percentage {
    right: 0;
    top: 0;
    height: 2.125rem;
    border-radius: 0.25rem !important;
    width: 2.125rem;
    font-weight: 600;
    font-size: 1rem;
}

.servicePercentage.selectPercentageAmount .percentage {
    left: 0;
    right: auto;
}

.servicePercentage.selectPercentageAmount input {
    padding-left: calc(2.125rem + 0.5rem);
}

.servicePercentage input,
.servicePercentage input::placeholder {
    font-size: 0.875rem !important;
}

.AddService {
    display: flex;
    gap: 0.5rem;
}

.maxWidth {
    max-width: 125px;
    width: 100%;
}

.AddServiceButton {
    width: 2.125rem;
    height: 2.125rem;
    min-width: 2.125rem;
    min-height: 2.125rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AddServiceButton {
    background-color: var(--success);
}

.AddServiceButton .ant-spin-spinning {
    margin-left: 4px;
}

.ServiceList {
    position: absolute;
    background-color: #fff;
    z-index: 9;
}

.ServiceList > div {
    overflow: auto;
    max-height: 150px;
}

.ServiceList .BondRelation .row *,
.ServiceList .BondRelation .pointerEvent * {
    pointer-events: none;
}

.ServiceList .selectedBid svg {
    width: 1rem;
    height: 1rem;
    max-width: 100%;
    flex: 0 0 1rem;
    position: relative;
    top: 2px;
}

.toogleDot svg {
    height: 1rem;
}

.toogleDot .profileDropDown {
    top: 2rem;
    z-index: 9;
    min-width: 5.75rem;
}

.profileDropDown .NavigationLink.txt-warning .labelContainer {
    color: var(--warning) !important;
}

.disable-bail-fee [data-image="Delete"] {
    display: none !important;
}

.Invoice .ServiceList > div {
    overflow: auto;
    max-height: 150px;
}

/* Contract Preview */
.contractPreviewImage {
    height: 110px;
    background: url("../../Assets/Images/Icons/contractPlaceholder.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* Request Document */
.requestDocumentAdd {
    height: 2.875rem;
    width: 2.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.hide-arrows .ant-input-number-handler-wrap {
    display: none;
}

/* Invoice */
.Invoice .invoiceFooter {
    border-radius: 10px;
}

.bondsmanInvoiceList .statusBadge {
    font-size: 0.75rem !important;
}

.bondsmanInvoiceList .statusBadge {
    border-radius: 0 0.313rem 0 0.313rem !important;
}

/* Payout */
.Payout .orClass {
    position: relative;
}

.Payout .orClass:before {
    content: "Or";
    position: absolute;
    transform: translate(-50%, -50%);
}

.PayoutStripe .stripe-logo img {
    flex: 0 0 68px;
    width: 68px;
    height: 68px;
}

.PayoutStripe.full-width .stripe-logo img {
    flex: 0 0 7.813rem;
    width: 7.813rem;
    height: 7.813rem;
}

.Payout .PayoutStripe.full-width .common__card > .card-body {
    padding: 1.25rem;
}

@media (min-width: 992px) {
    .Payout .orClass:before {
        right: -16px;
        top: 50%;
    }
}

@media (max-width: 992px) {
    .Payout .orClass:before {
        left: 50%;
        bottom: -46px;
    }
}

.min-width-320 {
    min-width: 280px;
}

.ant-tooltip {
    max-width: 300px;
}

.hr-black {
    border-top-color: rgba(0, 0, 0, 0.5);
}

/*  */
.bailAmountIcon svg {
    height: 0.875rem !important;
    flex: 0 0 0.875rem;
    margin-right: 0.25rem !important;
}

/* Contract Review */
.contractHeader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 1rem 1rem 0 1rem;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* AnalyticsReports */
.AnalyticsReports .svgFix svg {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
}

.AnalyticsReports .ma_verticalLine {
    width: 10px;
    margin-right: 5px;
}
