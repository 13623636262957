/* Client List and Detail CSS Start */
.client__card > .card-body {
    padding: 1.25rem;
}
.client-picture {
    max-width: 4rem;
    flex: 0 0 4rem;
    margin-right: 0.75rem;
}
.client-picture.cp__detail {
    max-width: 8.125rem;
    flex: 0 0 8.125rem;
    margin-right: 1.25rem;
}
.client-picture::before {
    content: "";
    display: block;
    padding-top: 100%;
}
.noEmail {
    color: #0d9af2;
}
.dateLabel {
    font-size: 0.75rem;
    color: #898989;
}
.gray_card_bg {
    background: #f6f6f6;
}
.gray_card_bg > .card-body {
    padding: 1.875rem;
}
.fs-36 {
    font-size: 2.25rem;
}
.gray_card_bg .fs-36 span {
    color: #333;
}
.page__card > .card-body {
    padding: 2.5rem;
}
@media (max-width: 767.98px) {
    .page__card > .card-body {
        padding: 1.5rem;
    }
    .client-picture.cp__detail {
        max-width: 8.125rem;
        margin: 0 auto;
        margin-right: auto;
        margin-bottom: 1rem;
    }
}
@media (max-width: 575.98px) {
    .client-picture {
        max-width: 3rem;
        flex: 0 0 3rem;
        margin-right: 0.5rem;
    }
    .client__card > .card-body {
        padding: 1rem;
    }
    .gray_card_bg .fs-5,
    .client__card .fs-5 {
        font-size: 1rem !important;
    }
    .gray_card_bg .fs-6,
    .client__card .fs-6 {
        font-size: 0.875rem !important;
    }
    .client__card .dateLabel {
        font-size: 0.625rem;
    }
    .page__card > .card-body,
    .gray_card_bg > .card-body {
        padding: 1rem;
    }
    .client-picture.cp__detail {
        max-width: 5rem;
        margin-bottom: 0.5rem;
    }
    .gray_card_bg .fs-36 {
        font-size: 1.375rem;
    }
    .gray_card_bg .fs-36 span.fs-5 {
        font-size: 0.75rem !important;
    }
}
/* Client List and Detail CSS End */

/* Earnings Overview CSS Start */
.common__card > .card-body {
    padding: 1.875rem;
}
.overview_round {
    width: 9.375rem;
    height: 9.375rem;
    flex: 0 0 9.375rem;
    border: 0.313rem solid;
}
.monthly__round {
    border-color: #0d9af2;
    background-color: rgba(13, 154, 242, 0.1);
}
.yearly__round {
    border-color: #f16d3d;
    background-color: rgba(241, 109, 61, 0.1);
}
.text-787878 {
    color: #787878;
}
.fs-26 {
    font-size: 1.625rem;
}
.fs-30 {
    font-size: 1.875rem;
}
.text-blue {
    color: #0d9af2;
}
.text-orrange {
    color: #f16d3d;
}
@media (max-width: 575.98px) {
    .common__card > .card-body {
        padding: 2rem 1rem;
    }
    .overview_round {
        width: 7rem;
        height: 7rem;
    }
    .common__card .fs-30 {
        font-size: 1.25rem;
    }
    .common__card .fs-5 {
        font-size: 1rem !important;
    }
}
/* Earnings Overview CSS End */

/* Client History & Management CSS Start */
.statusBadge {
    border-radius: 0px 10px;
}
.statusBadge svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    vertical-align: -0.063rem;
    flex: 0 0 0.75rem;
}
.client__card.client_h_card > .card-body {
    padding-top: 1.75rem;
}
.chat__btn svg {
    width: 1.625rem;
    height: 1.625rem;
    margin-right: 0.5rem;
}
@media (max-width: 575.98px) {
    .statusBadge svg {
        width: 0.5rem;
        height: 0.5rem;
        vertical-align: -0.015rem;
    }
    .btn-lg.chat__btn {
        --bs-btn-font-size: 1rem;
    }
    .chat__btn svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.25rem;
    }
}
/* Client History & Management CSS Start */

/* Calendar & Appointments Start */
.calebder__btn.btn-warning {
    width: 9.375rem;
    max-width: 100%;
    flex: 0 0 9.375rem;
    display: inline-flex;
    align-items: center;
    text-align: left;
    line-height: normal;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #f16d3d;
    --bs-btn-border-color: #f16d3d;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #ff4400;
    --bs-btn-hover-border-color: #ff4400;
}
.calebder__btn.btn-warning svg {
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 0.5rem;
}
@media (max-width: 575.98px) {
    .calebder__btn.btn-warning {
        --bs-btn-font-size: 0.75rem;
        width: 8rem;
        flex: 0 0 8rem;
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.75rem;
    }
    .calebder__btn.btn-warning svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.25rem;
    }
}
/* Calendar & Appointments End */

/* Educational Resources & Updates CSS Start */

.eru__card > .card-header::before {
    content: "";
    display: block;
    padding-top: 50%;
}
.read_more_link {
    color: #f16d3d;
}
.read_more_link svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
}
.eru__card_date {
    right: 0.625rem;
    top: 0.625rem;
    border-radius: 0.313rem;
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 0.313rem;
    -moz-border-radius: 0.313rem;
    -ms-border-radius: 0.313rem;
    -o-border-radius: 0.313rem;
}
.text-truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cru_main_img::before {
    content: "";
    display: block;
    padding-top: 50%;
}
@media (max-width: 575.98px) {
    .eru__card .fs-5 {
        font-size: 1rem !important;
    }
    .eru__card .fs-18 {
        font-size: 0.875rem;
    }
    .eru_detail__card .fs-6,
    .eru__card .fs-6 {
        font-size: 0.875rem !important;
    }
    .read_more_link svg {
        width: 0.875rem;
        height: 0.875rem;
        margin-left: 0.5rem;
    }
    .eru__card .fs-14 {
        font-size: 0.75rem;
    }
    .common__card.eru_detail__card > .card-body {
        padding: 1rem;
    }
    .eru_detail__card .fs-4 {
        font-size: 1.25rem !important;
    }
}
/* Educational Resources & Updates CSS End */

/* Payout Start CSS Start */
.payoutBox {
    border-radius: 0.625rem;
    background: rgba(13, 154, 242, 0.1);
    color: #0d9af2;
    font-size: 3.125rem;
    width: 11.875rem;
    flex: 0 0 11.875rem;
    height: 8.125rem;
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    -ms-border-radius: 0.625rem;
    -o-border-radius: 0.625rem;
}
.connect__btn {
    width: 10.25rem;
    max-width: 100%;
    flex: 0 0 10.25rem;
}
/* Payout Start CSS End */

/* Document Repository CSS Start */
.dr__card {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
.dr__card svg.foldersvg {
    width: 6.25rem;
    height: 6.25rem;
}
.dropdownElement {
    top: 0.375rem;
    right: 0.375rem;
    z-index: 1;
}
.dropdownElement .btn-light {
    width: 1.5rem;
    height: 1.5rem;
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #ffffff;
    border-radius: 0.313rem;

    -webkit-border-radius: 0.313rem;
    -moz-border-radius: 0.313rem;
    -ms-border-radius: 0.313rem;
    -o-border-radius: 0.313rem;
}

.folder_dr_card .card-body::before {
    content: "";
    display: block;
    padding-top: 60%;
}
.centerIcon {
    width: 3.75rem;
    height: 3.75rem;
}
@media (min-width: 1200px) {
    .row-cols-lg-6 > * {
        flex: 0 0 auto;
        width: 14.2857%;
    }
}

@media (max-width: 575.98px) {
    .dr__card svg.foldersvg {
        width: 5rem;
        height: 5rem;
    }
    .dr__card .fs-5 {
        font-size: 1rem !important;
    }
    .dr__card .fs-6 {
        font-size: 0.875rem !important;
    }
}

/* Document Repository CSS End */

/* Find Bondsman CSS Start */
.bondsManCard {
    width: 26.25rem;
    max-width: 100%;
    margin: 0 auto;
}
.bondsManCard,
.bondsManColCard {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.bondsManCard > .card-body {
    padding: 1.875rem 1.25rem 1.25rem;
}
.bondsmanProfile {
    max-width: 12.5rem;
}
.bondsmanProfile::before {
    content: "";
    display: block;
    padding-top: 100%;
}
.serviceCharge {
    line-height: normal;
    border: 1px solid #0d9af2;
}
.serviceCharge span + span {
    background-color: #0d9af2;
    color: #ffffff;
    border-radius: 0.5rem !important;
    min-height: 3.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bondsManColCard {
    color: #333;
    font-weight: 600;
    font-size: 1rem;
}
.bondsManColCard .card-body {
    padding: 0.75rem;
    white-space: nowrap;
}
.bondsManColCard svg {
    width: 1.563rem;
    height: 1.563rem;
    margin-bottom: 0.5rem;
}

.bondsManCard > .card-footer {
    border-radius: 0px 0px 0.625rem 0.625rem;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 0px 0px 0.625rem 0.625rem;
    -moz-border-radius: 0px 0px 0.625rem 0.625rem;
    -ms-border-radius: 0px 0px 0.625rem 0.625rem;
    -o-border-radius: 0px 0px 0.625rem 0.625rem;
}
.bondsManCard > .card-footer .btn-lg {
    --bs-btn-font-size: 1rem;
    text-transform: uppercase;
    --bs-btn-padding-x: 1.875rem;
}

.btn-warning.select-btn-lg {
    --bs-btn-color: #fff;
    --bs-btn-bg: #f16d3d;
    --bs-btn-border-color: #f16d3d;
}
.btn-warning.select-btn-lg svg {
    width: 1rem;
    height: 1rem;
    vertical-align: -0.188rem;
    margin-right: 0.25rem;
}
.btn-check:checked + .btn-warning.select-btn-lg span,
.btn-warning.select-btn-lg span + span {
    display: none;
}
.btn-check:checked + .btn-warning.select-btn-lg span + span {
    display: block;
}
.btn-check:checked + .btn-warning.select-btn-lg {
    background-color: #ffffff;
    color: #f16d3d;
    border-color: #f16d3d;
}
@media (max-width: 575.98px) {
    .bondsManCard > .card-body {
        padding: 1.5rem 1rem 1rem;
    }
    .bondsmanProfile {
        max-width: 9.5rem;
    }
    .bondsManCard .fs-5 {
        font-size: 1rem !important;
    }
    .bondsManColCard svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: 0.25rem;
    }
    .bondsManColCard {
        font-size: 0.75rem;
    }
    .bondsManCard > .card-footer .btn-lg {
        --bs-btn-font-size: 0.75rem;
    }
    .bondsManCard > .card-footer.p-3 {
        padding: 0.75rem !important;
    }
    .btn-warning.select-btn-lg svg {
        width: 0.75rem;
        height: 0.75rem;
        vertical-align: -0.125rem;
    }
}
/* Find Bondsman CSS End */

/* Find Bondsman Details CSS Start */
.text-333 {
    color: #333;
}
.card-shadow {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.bailBondDetail_card > .card-body {
    padding: 1.875rem;
}
.bailBondDetail_card .bondsmanProfile {
    margin-bottom: 0 !important;
    margin-right: 1.5rem;
    flex: 0 0 12.5rem;
}
.bb-detail-p p {
    display: flex;
}
.bb-detail-p p svg {
    width: 1.25rem;
    height: 1.25rem;
    flex: 0 0 1.25rem;
    margin-right: 0.5rem;
    margin-top: 0.375rem;
}
.bb__status.available {
    color: #007822;
}
.bb__status svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.25rem;
    vertical-align: 0px;
}
.bb_reviewStar svg {
    width: 2.75rem;
    margin-right: 1rem;
}
.bb_reviewStar svg:last-child {
    margin-right: 0;
}
@media (max-width: 767.98px) {
    .bailBondDetail_card > .card-body {
        padding: 1.5rem 1rem;
    }
    .bailBondDetail_card .bondsmanProfile {
        margin-right: auto;
        margin-left: auto;
        display: block !important;
    }
    .bb-detail-p h3.fs-36 {
        font-size: 1.5rem;
        text-align: center;
        margin-top: 1rem;
    }
    .bb-detail-p p svg {
        width: 1rem;
        height: 1rem;
        flex: 0 0 1rem;
        margin-right: 0.25rem;
        margin-top: 0.25rem;
    }
    .bailBondDetail_card .fs-5 {
        font-size: 1rem !important;
    }
    .bb_reviewStar svg {
        width: 1.875rem;
    }
    .bailBondDetail_card .fs-4 {
        font-size: 1rem !important;
    }
}
/* Find Bondsman Details CSS End */

/* Select Existing Request BOX CSS Start */
.se_request_Card {
    width: 26.25rem;
    max-width: 100%;
    border-color: transparent;
}
.date__time {
    color: #898989;
}
.se_request_Card.selected {
    border-color: #3aa61c;
}
.selectedBandIcon {
    width: 1.75rem;
    height: 1.75rem;
}
@media (max-width: 575.98px) {
    .se_request_Card {
        width: 90%;
    }
    .se_request_Card .fs-6 {
        font-size: 0.75rem !important;
    }
    .se_request_Card .fs-5 {
        font-size: 1rem !important;
    }
}
/* Select Existing Request BOX CSS End */

.dark-card {
    background-color: #000;
}
.sendCount svg {
    width: 0.75rem;
    margin-right: 0.5rem;
}
.profilelist_ul li {
    margin-left: -1.875rem;
}
.profilelist_ul li:first-child {
    margin-left: 0;
}
.profilelist_ul .profileList_li {
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #fff;
    overflow: hidden;
    position: relative;
}
.profilelist_ul .profileList_li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
.profilelist_ul .profileList_li span {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
}

.btn-lg.page-btn {
    text-transform: uppercase;
    --bs-btn-padding-x: 1.875rem;
    --bs-btn-font-size: 1rem;
    font-weight: 600;
    border-radius: 0.375rem;
    -webkit-border-radius: 0.375rem;
    -moz-border-radius: 0.375rem;
    -ms-border-radius: 0.375rem;
    -o-border-radius: 0.375rem;
}
.btn-warning.page-btn {
    --bs-btn-color: #fff;
    --bs-btn-bg: #f16d3d;
    --bs-btn-border-color: #f16d3d;
}

/* Notification Page CSS Start */
.noti__card > .card-body {
    padding: 0.75rem 2.5rem;
}
.notification_column svg {
    width: 2.5rem;
    flex: 0 0 2.5rem;
    margin-right: 0.875rem;
    margin-top: 0.25rem;
}
.noti__date {
    color: #343a3f;
}
.text-noti-paragraph {
    color: #898989;
}
.noti__card ul li {
    border-bottom: 1px solid #d9d9d9;
}
.noti__card ul li:last-child {
    border-bottom: 0;
}
@media (max-width: 767.98px) {
    .noti__card > .card-body {
        padding: 1rem 1rem;
    }
    .noti__card .fs-6 {
        font-size: 0.75rem !important;
    }
    .notification_column svg {
        width: 2rem;
        flex: 0 0 2rem;
        margin-right: 0.5rem;
        margin-top: 0;
    }
}
/* Notification Page CSS End */
