/* BondRelation Start*/
.BondRelation input[type="radio"] {
  visibility: hidden;
  height: 0;
  width: 0;
  display: grid;
}

.BondRelation.isTabActive .main-sec-box {
  background-color: #0d9af2 !important;
  border-color: #0d9af2;
}

.BondRelation.isTabActive .main-sec-box svg path[fill="#0D9AF2"] {
  fill: #ffffff;
}

.BondRelation.isTabActive .main-sec-box .text-black {
  color: #ffffff !important;
}
/* BondRelation End*/

/* Swiper */
.customSwiper .swiper-button-next,
.customSwiper .swiper-button-prev {
  background-color: var(--success);
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.customSwiper .swiper-button-next:after,
.customSwiper .swiper-button-prev:after {
  color: #fff;
  font-size: 16px;
}

.customSwiper .swiper-button-next {
  right: 0 !important;
}

.customSwiper .swiper-button-prev {
  left: 0 !important;
}
/* Swiper */

/* Antd Collapse(FAQ) */
.antdFaqs {
  font-family: "Open Sans";
}

.antdPanelItem {
  box-shadow: 0px 0px 20px 0px #0000001a;
}

.antdPanelItem .ant-collapse-expand-icon img {
  max-height: 1.5rem;
}

.antdPanelItem .ant-collapse-header {
  flex-direction: row-reverse;
  gap: 20px;
}

.antdPanelItem .ant-collapse-expand-icon {
  padding-inline-end: 0px !important;
  position: relative;
  top: 0.6rem;
  height: 22px !important;
  width: 22px;
  display: grid;
  place-items: center;
}

.antdPanelItem .ant-collapse-header-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a1a1a !important;
}

.antdPanelItem .ant-collapse-content-box {
  padding-top: 0 !important;
  color: #1a1a1a !important;
}

@media (min-width: 991.98px) {
  .antdFaqs {
    font-family: "Open Sans";
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    align-items: baseline;
  }
}
/* Antd Collapse(FAQ) End*/

/* Page BannerBackground */
.linearbg-banner {
  height: 10rem;
  background: #f6f6f6;
}
/* Page BannerBackground */

/* Footer Button Submit Handler For Modal  */
.submitHandler .antdButton {
  height: 2.875rem !important;
}

.submitHandler .antdButton:hover {
  color: #fff !important;
  border-color: transparent !important;
}

.submitHandler .backHandler:hover {
  color: var(--error) !important;
  border-color: transparent !important;
}
/* Footer Button Submit Handler For Modal  */

/* Antd Modal */
.antdModal {
  font-family: "Open Sans";
}

.antdModal .antdModalTitle {
  font-weight: 700;
  font-size: 1.75rem;
  margin: 0 auto;
  max-width: 85%;
  text-align: center;
  line-height: 2.383rem;
}

.antdModal .antdModalTitle .privacyIcon {
  position: relative;
  top: -0.25rem;
  height: 2rem;
}

.ant-modal-footer {
  display: none;
}

.ant-modal .ant-modal-close:hover {
  background-color: transparent !important;
}

.ant-modal .ant-modal-close {
  inset-inline-end: 0;
  right: 12px;
}

@media (max-width: 767.98px) {
  .antdModal .antdModalTitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .antdModal .antdModalTitle .privacyIcon {
    top: -0.25rem;
    height: 1.5rem;
  }
}
/* Antd Modal End*/

/* Formik */
.formik .form-check-input {
  width: 1.375rem;
  height: 1.375rem;
}

.privacyIcon {
  height: 1rem;
}

/* RadioButtonSelectAnyOne */
.RadioButtonSelectAnyOne input[type="radio"] {
  width: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

/* Searchbar */
/* Search Bar */
.searchBar {
  height: max-content;
}

.searchBar .searchIcon,
.searchBar .clearSearch {
  height: 1.25rem;
  position: absolute;
  right: 0.625rem;
  top: 50%;
  -webkit-transform: translate(-25%, -50%);
  transform: translate(-25%, -50%);
}

.searchBar input:placeholder-shown {
  text-overflow: ellipsis;
}

.searchBar input::placeholder,
.placeholder-color {
  color: rgba(155, 155, 155, 1);
  font-size: 1rem;
}

.searchBar .searchInput {
  padding-right: 1.875rem;
}

/* My Case - Card */
.CaseTypeCard .case-title {
  height: 1.875rem;
  border-radius: 0px 0.625rem 0px 0.625rem;
}

.CaseTypeCard .locationSvg svg {
  min-width: 14px;
  margin-top: 0.25rem;
}

.CaseTypeCard .star svg {
  min-width: 14px;
  max-width: 14px;
}

.case-status-text {
  background-color: rgba(5, 243, 219, 0.1);
  border-radius: 0 0 0.625rem 0.625rem;
}

.user-image img {
  height: 2.875rem;
  width: 2.875rem;
  min-width: 2.875rem;
  min-height: 2.875rem;
}

.CaseTypeCard.Cancelled .case-title {
  display: none !important;
}

.CaseTypeCard.Canceled .case-title {
  display: none !important;
}

@media (max-width: 575.98px) {
  .CaseTypeCard .f20 {
    font-size: 0.875rem;
  }

  .user-image img {
    height: 2.25rem;
    width: 2.25rem;
    min-width: 2.25rem;
    min-height: 2.25rem;
  }

  .ant-rate {
    font-size: 1rem;
  }

  .user-chat svg {
    transform: scale(0.875);
  }
}

/* Antd Dragger */
.antdDragger {
  font-family: "Open Sans";
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  align-items: center;
}

.antdDragger * {
  font-family: "Open Sans";
}

.antdDragger.ant-upload-wrapper .ant-upload-drag,
.uploadPreviewBorder,
.custom-ant-upload-list {
  border: 1px dashed rgba(241, 109, 61, 1);
  background-color: rgba(246, 246, 246, 1);
  border-radius: 8px;
}

.antdDragger.ant-upload-wrapper:hover .ant-upload-drag {
  border: 1px dashed rgba(241, 109, 61, 1);
}

.antdDragger .ant-upload .ant-upload-btn,
.uploadPreviewBorder {
  min-height: 150px;
}

.antdDragger.ant-upload-wrapper .ant-upload-list {
  display: none;
}

.antdDragger .onRemove,
.UploadFile .onRemoveDocument {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.UploadFile .border-s {
  position: relative;
}

.antdDragger.ant-upload-wrapper.docUpload {
  position: relative;
  flex-direction: column !important;
}
.antdDragger.ant-upload-wrapper.docUpload .ant-upload-list {
  display: none;
  width: 100%;
}

.antdUpload.antdDragger.docUpload .beforeUpload {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.antdUpload.antdDragger.docUpload .beforeUpload.show {
  background-color: rgba(0, 0, 0, 0.85);
}

.onProgress .antdDragger.ant-upload-wrapper .ant-upload-drag {
  display: none;
}

.previewFile .uploadPreviewBorder {
  border-color: transparent;
}

.previewFile .uploadPreviewBorder .uploadedFileSuccess svg {
  height: 50px;
}

.onProgress .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions {
  display: none;
}

.custom-ant-upload-list {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
/* My Case - Detail - DetailHandle & Bondsman */
.DetailHandle .profileDropDown .NavIconBG,
.BondmanDetail .profileDropDown .NavIconBG,
.BondsmanCard .profileDropDown .NavIconBG {
  display: none !important;
}

.DetailHandle .profileDropDown,
.BondmanDetail .profileDropDown,
.BondsmanCard .profileDropDown {
  top: 3rem;
  z-index: 9;
  grid-template-columns: auto;
}

.DetailHandle .profileDropDown .NavigationLink,
.BondmanDetail .profileDropDown .NavigationLink,
.BondsmanCard .profileDropDown .NavigationLink {
  min-width: max-content;
}

.DetailHandle .profileDropDown .NavigationLink .labelContainer,
.BondmanDetail .profileDropDown .NavigationLink .labelContainer,
.BondsmanCard .profileDropDown .NavigationLink .labelContainer {
  color: var(--red) !important;
  margin-left: 0 !important;
  font-size: 1rem !important;
}

.CaseReminder {
  background-color: #272727;
  color: #fff;
}

.CaseReminder svg {
  height: 5.563rem;
}

.BondsmanCard svg,
.RequestForServiceCard svg {
  height: 1.5rem;
}

.fileUploadUser img {
  height: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
}

@media (max-width: 575.98px) {
  .CaseReminder {
    text-align: center;
  }

  .CaseReminder svg {
    height: 4rem;
    margin-bottom: 0.5rem;
  }

  .CaseReminder .f24 {
    font-size: 1rem;
  }

  .CaseReminder .f16 {
    font-size: 0.875rem;
  }

  .BondsmanCard .btn,
  .FooterSticky .btn {
    font-size: 0.875rem !important;
  }
}

/* Footer Mobile - Sticky */
@media (max-width: 768.98px) {
  .FooterSticky {
    position: fixed;
    width: 100%;
    height: 60px;
    bottom: 0;
    background-color: #fff;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    box-shadow: 0px 0px 20px 0px #0000001a;
  }
}

/* Antd Button */
.antdButton,
.antdButton.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: transparent !important;
}

.antdModal .antdSVG svg {
  max-height: 4.5rem;
}

/* BailBondsmanDetailCard */
.BailBondsmanDetailCard img {
  width: 8rem;
  height: 8rem;
}

@media (max-width: 768.98px) {
  .BailBondsmanDetailCard .f32 {
    font-size: 1.5rem;
  }

  .BailBondsmanDetailCard .f20 {
    font-size: 1rem;
  }

  .BailBondsmanDetailCard .workingHours {
    font-size: 1rem !important;
  }
}

/* Antd Select */
.antdSelect {
  height: 3rem !important;
}

.antdSelect.item-selected .ant-select-arrow {
  display: none;
}

.antdSelect.item-selected .ant-select-clear {
  opacity: 1;
}

.uploadProfile .antdSelect.item-selected .ant-select-arrow {
  display: block;
}

/* Antd Table */
.antdTable * {
  font-family: "Open Sans" !important;
}

.antdTable .ant-table-content table {
  border: 0.063rem solid var(--primary);
}

.antdTable.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.antdTable.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.antdTable .ant-table-content::-webkit-scrollbar {
  display: none;
}

/* Profile */
.uploadProfile .customerProfileAvatarBox {
  display: inline-flex;
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;
}

.uploadProfile .customerProfileAvatarImage {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  font-size: 1.25rem;
  height: 12.5rem;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 12.5rem;
}

.uploadProfile .customerProfileAvatarImage img {
  color: transparent;
  height: 100%;
  text-align: center;
  text-indent: 10000px;
  width: 100%;
}

.uploadProfile .customerProfileAvatarImage.is-active img {
  border: 0.063rem solid var(--primary-opacity);
  border-radius: 50%;
  object-fit: cover;
}

.uploadProfile .customerProfileAvatarChange {
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  bottom: 14%;
  box-shadow: 0 0.375rem 0.313rem 0 rgba(52, 58, 63, 0.09);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.75rem;
  font-weight: 500;
  height: 3.75rem;
  width: 3.75rem;
  justify-content: center;
  line-height: 1;
  min-width: 2.625rem;
  position: absolute;
  -webkit-transform: scale(1) translate(50%, 50%);
  transform: scale(1) translate(50%, 50%);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
  right: 12%;
  bottom: 4.25rem;
}

.uploadProfile .customerProfileUpload .ant-upload-list {
  display: none;
}

.BondsManProfile .antdSelect {
  width: 100%;
}

.timePlus svg {
  height: 3rem;
  width: 3rem;
}

.deleteTime {
  height: 2rem;
  width: 2rem;
}

@media (max-width: 768px) {
  .uploadProfile .customerProfileAvatarImage {
    width: 10rem;
    height: 10rem;
  }

  .uploadProfile .customerProfileAvatarChange {
    height: 2.625rem;
    width: 2.625rem;
    right: 14%;
    bottom: 3 0.25rem;
  }
}

.HeaderRemove .header-container {
  display: none;
}

.HeaderRemove .header-container {
  display: none;
}

.HeaderRemove .NavigationBar {
  display: none;
}

.HeaderRemove .BondsManView .Views {
  margin-left: 0 !important;
  padding: 0 !important;
}

.HeaderRemove .UnderReview {
  height: 100%;
}

.HeaderRemove {
  background-color: var(--light) !important;
}

.CompleteProfile .uploadProfile {
  margin-top: 0 !important;
  margin-bottom: 1.5rem !important;
}

@media (min-width: 768px) {
  .BondsManProfile .antdSelect {
    max-width: 20rem;
  }

  .CompleteProfile .spaceAround {
    padding: 2.5rem !important;
    max-width: 1500px;
    margin: 0 auto;
  }
}

/* Preview Invoice */
.previewInvoiceModal .ant-modal-content {
  padding: 0;
  background-color: #f6f6f6;
}

.antdModalInovice,
.antdModalInovice * {
  font-family: "Open Sans";
}

.antdModalInovice.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: var(--primary) !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.antdModalInovice tbody tr:nth-child(even) {
  background-color: #f2f2f2; /* Set your desired background color */
}

.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.previewInvoiceModal .ant-modal-content,
.maxHeightModal .ant-modal-content {
  max-height: min(80vh, 43.75rem);
  overflow-y: scroll;
}

.antdModalInovice .ant-table-tbody,
.antdModalInovice .ant-table-tbody *,
.antdModalTable .ant-table-tbody {
  color: #3a343f;
}
/* Antd Zip Code */
.antdPostCode {
  min-height: calc(1.875rem + 1rem + calc(var(--bs-border-width) * 2));
  width: 100%;
  font-family: "Open Sans";
}

.antdPostCode .ant-select-selection-item,
.antdPostCode .ant-select-selector input,
.antdPostCode .ant-select-selector input::placeholder,
.antdPostCode .ant-select-selection-placeholder,
.antdDatePicker .ant-picker-input input::placeholder {
  font-size: 1rem;
  color: #9b9b9b;
}

.antdDatePicker .ant-picker-input input,
.antdTimePicker .ant-picker-input input {
  font-size: 1rem;
  font-family: "Open Sans";
}

.antdPostCode .ant-select-selection-item {
  color: #000000 !important;
  font-family: "Open Sans";
}

.antdDatePicker.selected .ant-picker-suffix,
.antdSelect.selected .ant-picker-suffix,
.antdSelect.selected .ant-select-arrow {
  display: none;
}

.antdDatePicker.selected .ant-picker-clear,
.antdSelect.selected .ant-picker-clear,
.antdSelect.selected .ant-select-clear,
.bondmanlistDateFilter.selected .ant-picker-clear {
  opacity: 1;
}

.antdSelect.selected .ant-select-clear {
  display: block;
  font-size: 16px;
  margin-top: -8px;
}

.antdDatePicker.selected .ant-picker-clear {
  font-size: 16px;
}

.antdDatePicker.selected.px-11 .ant-picker-clear {
  font-size: 16px;
  right: -5px;
  margin-top: -1px;
}

.px-11 {
  padding-left: 11px;
  padding-right: 11px;
}

/* Checkbox */
.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* Antd Time Picker */
.antdTimePicker .ant-picker-input input::placeholder {
  font-size: 1rem;
  color: #9b9b9b;
}

/* Toggle For Time Picker */
.ant-switch {
  background: #dcdcdc !important;
}

.ant-switch.ant-switch-checked {
  background: var(--success) !important;
}

.workingHoursFuncationality .disable svg [stroke="#F16D3D"] {
  stroke: #dcdcdc;
}

.workingHoursFuncationality .disable svg [fill="#F16D3D"],
.workingHoursFuncationality .disable svg [fill="#FF0000"] {
  fill: #dcdcdc;
}

.workingHoursFuncationality .ant-picker-disabled [fill="#0D9AF2"] {
  fill: #dcdcdc;
}

/* Antd Image Preview Start */
.ant-image .ant-image-mask {
  border-radius: 50%;
}
/* Antd Image Preview Ends */
/* OnClick Border Issue */
.btn:first-child:active {
  border-color: transparent !important;
}

/* Radio Button Color Change */
.radioButtonCondition {
  accent-color: var(--success) !important;
}

/* Rating Bondman Detail */
.bondsman-detail-rating svg {
  height: 0.875rem;
}

/* Contract Card */
.ContractCard {
  position: relative;
  min-height: 10.625rem;
}

.ContractCard img {
  min-height: 10.625rem;
  width: 100%;
  position: absolute;
  border-radius: 0.875rem;
}

@media (max-width: 767.98px) {
  .ContractCard .f32 {
    font-size: 1.75rem;
  }

  .ContractCard .f16 {
    font-size: 0.875rem;
  }

  .ContractCard .btn {
    height: 2.5rem !important;
    margin-top: 0.75rem;
  }
}

@media (max-width: 575.98px) {
  .ContractCard .f32 {
    font-size: 1.25rem;
  }

  .ContractCard .f16 {
    font-size: 0.875rem;
  }

  .ContractCard .btn {
    height: 2.25rem !important;
    margin-top: 0.5rem;
  }
}

/* Client List html */
.client-picture {
  max-width: 4rem;
  flex: 0 0 4rem;
  margin-right: 0.75rem;
}

.client-picture::before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* Bondsman - Payout - Stripe */
.Payout .PayoutStripe .inProgress svg {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
}

/* Review & Feedback */
.reviewForm .form-control-lg {
  min-height: calc(1.5rem + 1.5rem + calc(var(--bs-border-width) * 2)) !important;
  font-size: 1rem;
}

.reviewForm .submitBtn {
  width: 3.125rem;
  height: 3.125rem;
  flex: 0 0 3.125rem;
}

.reviewModal .chatReply {
  border-radius: 0.625rem 0.625rem 0 0.625rem;
}

/* Document Ref */
.DocumentRepositoryCard .profileDropDown {
  top: 0.3rem;
  right: 0.375rem;
  grid-template-columns: 1fr;
}

/* File Download Icon For Request Detail */
.FileIcon svg {
  width: 3.125rem;
  height: 3.125rem;
  flex: 0 0 3.125rem;
}

.invoiceAction svg,
.dropdownForDownloadInvoice .btn {
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 1.5rem;
}

.dropdownForDownloadInvoice .profileDropDown {
  grid-template-columns: 1fr;
  right: 0.5rem;
  top: 3rem;
  z-index: 1;
}

.dropdownForDownloadInvoice .profileDropDown .NavigationLink .labelContainer {
  min-width: max-content;
}

/* Find Bondsman */
.antdCheckBoxGroup .bondsManCard > .card-footer .antdCheckBox:hover,
.antdCheckBoxGroup .bondsManCard > .card-footer .antdCheckBox:active {
  color: #fff !important;
  background-color: var(--error);
  border-color: var(--error);
}

.antdCheckBoxGroup .bondsManCard > .card-footer .antdCheckBox.ant-checkbox-wrapper-checked:hover,
.antdCheckBoxGroup .bondsManCard > .card-footer .antdCheckBox.ant-checkbox-wrapper-checked:active {
  background-color: #ffffff;
  color: #f16d3d !important;
  border-color: #f16d3d;
}

.FilterDataCheckBox,
.FilterDataCheckBox * {
  font-family: "Open Sans";
}

.antdCheckBoxGroup {
  column-gap: 0 !important;
}

.antdCheckBoxGroup .antdCheckBox .ant-checkbox-inner {
  display: none;
}

.antdCheckBoxGroup .antdCheckBox span + span {
  padding-left: 0 !important;
}

.antdCheckBoxGroup .antdCheckBox span + span {
  display: inline-block !important;
}

.antdCheckBoxGroup .ant-checkbox + span {
  padding-inline: 0 !important;
}

.antdCheckBoxGroup .antdCheckBox .selected,
.antdCheckBoxGroup .antdCheckBox.ant-checkbox-wrapper-checked .select {
  display: none !important;
}

.antdCheckBoxGroup .antdCheckBox.ant-checkbox-wrapper-checked .selected {
  display: block !important;
}

.antdCheckBoxGroup .antdCheckBox.ant-checkbox-wrapper-checked {
  background-color: #ffffff;
  color: #f16d3d;
  border-color: #f16d3d;
}

.FilterDataCheckBox .bondsManCard {
  border-color: transparent !important;
  transition: all 0.25s;
}

.FilterDataCheckBox .bondsManCard.isSelected {
  border: 1px solid #f16d3d !important;
}

.bondsManCard > .card-footer .btn-lg.c-success {
  border-color: var(--success);
}

/* Selected Card */
.SelectBailCard.isSelected svg [fill="#F16D3D"] {
  fill: var(--success) !important;
}

.SendRequestModal .ant-modal-content {
  padding: 0 !important;
}

.SendRequestModal .ant-modal-body {
  max-height: min(80vh, 43.75rem);
  overflow-y: scroll;
}

.SendRequestModal .ant-modal-footer {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999; /* Set a higher z-index to make sure the footer is on top of other elements */
}

.SendRequestModal .SendRequestModal-Footer {
  border-radius: 0 0 8px 8px;
}

/* Requested Bondsman List */
.RequestDetail .profilelist_ul .ant-avatar-group > *:not(:first-child) {
  margin-inline-start: -20px;
}

.RequestDetail .profilelist_ul .ant-avatar-group .ant-avatar {
  border: 1.5px solid #fff !important;
}

.antdAvatar,
.antdAvatar * {
  font-family: "Open Sans";
}

/* List Place Bid */
.List-Bondman .who-place-bid .bondsManCard > .card-body {
  background-color: #e6f5ff;
  border-radius: 0.625rem 0.625rem 0 0 !important;
}

.ViewIndex .RequestDetail .bailBondDetail_card > .card-body {
  padding: 1.25rem;
}

.ViewIndex .RequestDetail .bailBondDetail_card .bondsmanProfile {
  flex: 0 0 10rem;
}

.ViewIndex .RequestDetail .bailBondDetail_card .bb-detail-p p svg,
.ViewIndex .RequestDetail .bailBondDetail_card .rating_experience svg {
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
  margin-top: 0.275rem;
}

.ViewIndex .RequestDetail .bailBondDetail_card .rating_experience svg {
  margin-top: -0.275rem;
}

@media (max-width: 1199.98px) {
  .ViewIndex .RequestDetail .bailBondDetail_card .bondsmanProfile {
    margin-right: auto;
    margin-left: auto;
    display: block !important;
  }
}

/* Postal Code Clear */
.antdPostCode.item-selected .ant-select-arrow {
  display: none;
}

.antdPostCode .ant-select-clear {
  display: none;
}

.antdPostCode.item-selected .ant-select-clear {
  opacity: 1;
}

.antdPostCode.item-selected .ant-select-clear {
  display: block;
  font-size: 16px;
  margin-top: -8px;
}

/* Antd Upload */
.antdUpload .ant-upload-select {
  width: 100%;
}

.dash-border {
  border: 1px dashed var(--primary);
}

.light .dash-border {
  border: 1px dashed #121212;
}

.antdUpload.uploaded .ant-upload-select {
  display: none;
}

.antdUpload.uploaded .ant-upload-select {
  display: none;
}

.antdUpload.uploaded .ant-upload-list-picture .ant-upload-list-item-error {
  border-color: var(--green) !important;
}

.antdUpload.uploaded .ant-upload-list-picture .ant-upload-list-item-name {
  color: var(--green) !important;
}

.antdUpload.uploaded .ant-tooltip-placement-top {
  display: none !important;
}

.antdUpload.uploaded .ant-upload-list-item-name {
  text-decoration: none !important;
}

.antdUpload.uploaded .ant-upload-list-item-done {
  border-color: var(--green) !important;
}

.antdUpload.uploaded .ant-btn-icon {
  color: var(--red);
}

.antdUpload.uploaded .ant-upload-list-item-image {
  object-fit: cover;
}

.antdUpload .ant-upload-list-picture {
  display: none;
}

.antdUpload.uploaded.customUploader .ant-upload-list-picture {
  display: block;
}

.antdUpload.uploaded.modalPurpose .ant-upload-list-picture {
  display: none;
}
.antdUpload.uploaded.modalPurposeOpen .ant-upload-list-picture {
  display: block;
}

.antdUpload.antdUploadBulk .ant-upload-list-picture {
  display: block;
}

.antdModal .ant-modal-content {
  max-height: min(80vh, 700px);
  overflow-y: scroll;
}

.antdUpload .anticon-delete {
  color: red !important;
}

.antdUpload.uploaded .ant-upload-list-picture .anticon-file {
  font-size: 30px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions.picture .ant-upload-list-item-action {
  background-color: transparent !important;
}

/* Antd Upload */

/* PDF Viewer Css */
#PDF_Display_Section {
  overflow: auto;
}

#PDF_Display_Section .pdf-container .react-pdf__Page {
  background-color: transparent !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  height: auto !important;
}

.pdf-container {
  width: 100%;
  overflow-x: auto; /* Add horizontal scrolling if necessary */
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

/* Review Contract Modal */
.reviewContractModal .ant-modal-content {
  padding: 0 !important;
}

.reviewContractModal .ant-modal-content .ant-modal-body {
  padding: 2rem;
}

@media screen and (max-width: 767.98px) {
  .reviewContractModal {
    width: calc(100vw - 1rem) !important;
  }

  .reviewContractModal .ant-modal-content .ant-modal-body {
    padding: 1rem;
  }
}

/* Complete Request - My Case */
.CaseTypeCard .toggleCase,
.bondsmanDetailForDelete .delete-request .toggleCase {
  background-color: #ffffff;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  border: 0;
  display: grid;
  place-items: center;
}

.delete-request {
  position: absolute;
  right: 1rem;
  bottom: 0.4rem;
}

.CaseTypeCard .delete-request .profileDropDown {
  grid-template-columns: 1fr;
  top: calc(100% + 0.75rem);
  min-width: max-content;
  padding: 0.65rem 1rem !important;
  border-radius: 6px !important;
  z-index: 9;
}

.delete-request .profileDropDown .NavigationLink .labelContainer {
  color: var(--red) !important;
}

.bondsmanDetailForDelete .delete-request {
  top: 0.5rem;
}

.bondsmanDetailForDelete .delete-request .profileDropDown {
  grid-template-columns: 1fr;
  bottom: unset;
  min-width: max-content;
  padding: 0.65rem 1rem !important;
  border-radius: 6px !important;
  z-index: 9;
  top: 2.5rem !important;
}

.BulkImportBondsMan .RadioButtonSelectAnyOne.c-error {
  background-color: var(--red) !important;
}

.BulkImportBondsMan .RadioButtonSelectAnyOne.b-error {
  border-color: var(--red) !important;
  color: var(--red) !important;
}

.BulkImportBondsMan .RadioButtonSelectAnyOne.zeroAssign {
  display: none !important;
}

/* Notfication */
.NotificationList .Notification-items .notification-icon {
  flex: 0 0 40px;
}

.Notification-items {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  /* border-bottom: 1px solid #d9d9d9; */
}

.NotificationList .Notification-items:last-child {
  /* border-bottom: none; */
}

.NotificationList .Notification-items .notification-detail .notification-date {
  flex: 0 0 150px;
  margin-left: 0.5rem;
}

.svgIsActive [fill="#F2F2F2"] {
  /* fill: var(--red); */
}

.svgIsActive [fill="#A2A2A2"] {
  /* fill: #fff; */
}

.svgIsActive.notificationNumber {
  /* color: var(--) !important; */
  font-weight: 900;
}

.svgIsActive [fill="#0D9AF2"] {
  fill: var(--red);
}

.custom-border {
  height: 1px;
  background: #d9d9d9;
  margin: 0.5rem 0;
  opacity: 0.5;
}
