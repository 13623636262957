.AdminIndex,
.BondsManView {
    position: relative;
}

.AdminIndex::before,
.BondsManView::before {
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: var(--light);
    z-index: -1;
}

/* Rangepicker */
.bookingDatePicker,
.AdminIndex .searchBar input {
    height: 2.75rem;
    border: 1px solid rgba(220, 220, 220, 1);
}

.ant-picker-range-wrapper,
.ant-image .ant-image-mask {
    font-family: "Open Sans";
}

.ant-picker .ant-picker-suffix svg {
    height: 0.875rem;
}

/* Bondsman List */
.AdminIndex .searchBar [fill="#3AA61C"] {
    fill: var(--error) !important;
}

.AdminIndex .searchBar [stroke="#3AA61C"] {
    stroke: var(--error) !important;
}

.AdminIndex .BondsmanList [for="all"] {
    border: 1px solid var(--error);
    color: var(--error) !important;
}

.AdminIndex .BondsmanList [for="all"].c-error {
    background-color: var(--error) !important;
    color: #fff !important;
}

.AdminIndex .BondsmanList [for="new"] {
    border: 1px solid var(--success);
    color: var(--success) !important;
}

.AdminIndex .BondsmanList [for="new"].c-error {
    background-color: var(--success) !important;
    color: #fff !important;
}

.AdminIndex .BondsmanList [for="pending"] {
    border: 1px solid var(--grey);
    color: var(--grey) !important;
}

.AdminIndex .BondsmanList [for="pending"].c-error {
    background-color: var(--grey) !important;
    color: #fff !important;
}

.AdminIndex .BondsmanList [for="approved"] {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
}

.AdminIndex .BondsmanList [for="approved"].c-error {
    background-color: var(--primary) !important;
    color: #fff !important;
}

.AdminIndex .BondsmanList [for="rejected"] {
    border: 1px solid var(--red) !important;
    color: var(--red) !important;
}

.AdminIndex .BondsmanList [for="rejected"].c-error {
    background-color: var(--red) !important;
    color: #fff !important;
}

.AdminIndex .BondsmanList [for="clear"] {
    color: var(--red) !important;
    background-color: transparent !important;
    padding-left: 0 !important;
    border: 0 !important;
}

.AdminIndex .BondsmanList .selectBondManListType [for="clear"] {
    display: none !important;
}

.AdminIndex .BondsmanList .selectBondManListType.isClear [for="clear"] {
    display: flex !important;
}

.AdminIndex .BondsmanList .PlatFormFees svg {
    height: 14px;
}

.AdminIndex .BondsmanList .locationSvg svg {
    flex: 0 0 14px;
}

/* Admin - Bondsman - */
.AdminIndex .BondsmanListCard .profileDropDown {
    grid-template-columns: repeat(1, 1fr);
    min-width: max-content;
    top: 1.5rem;
    right: 0.5rem;
}

.AdminIndex .BondsmanListCard .dropdownElement [fill="#BF9E61"] {
    fill: #000;
}

/* Dashboard */
.pendingBailRequest h4 {
    color: #d94100;
}

.completedRequest h4 {
    color: #007822;
}

.bondsmanlist h4 {
    color: #0d9af2;
}

.clientlist h4 {
    color: #0055b9;
}

.totalBill h4 {
    color: #431e1e;
}

.totalEarning h4 {
    color: #4ca7b3;
}

.taxAmount h4 {
    color: #4731cb;
}

.netEarning h4 {
    color: #c21669;
}

/* Antd Theme Table */
.antdThemeTable,
.antdThemeTable * {
    font-family: "Open Sans";
}

.antdThemeTable.ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #e9e9e9 !important;
    font-size: 1rem;
}

.antdThemeTable.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 10px;
    border-bottom-left-radius: 10px;
}

.antdThemeTable.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:last-child
    > *:last-child {
    border-bottom-right-radius: 10px;
}

.antdThemeTable.ant-table-wrapper table {
    border-radius: 10px;
}

.antdThemeTable.ant-table-wrapper .ant-table {
    border-radius: 10px 10px 0 0;
    border: 1px solid #dcdcdc;
}

.antdThemeTable.ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dcdcdc;
}

.antdThemeTable.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
}

.antdThemeTable .ant-table-content {
    overflow-y: auto;
}

.antdThemeTable .ant-table-content::-webkit-scrollbar {
    display: none;
}

/* Email Template */
.EmailTemplate .searchBar .searchIcon,
.EmailTemplate .searchBar .clearSearch {
    right: 1.5rem;
}

.EditEmailTemplate .ck-content {
    min-height: 10rem;
}

.pagination .currentpage,
.pagination .currentpage:hover {
    border-color: var(--error) !important;
    z-index: 1;
}

.pagination .page-item:last-child .page-link {
    z-index: 2 !important;
}

.pagination button:focus:not(:focus-visible) {
    box-shadow: none !important;
}

.pagination .page-link.disabled,
.pagination .page-link:focus {
    background-color: #fff;
}

/*  */
.bondmanlistDateFilter .ant-picker-input input::placeholder {
    font-size: 1rem;
    color: #9b9b9b;
}

.bondmanlistDateFilter .ant-picker-input input {
    font-size: 1rem;
    font-family: "Open Sans";
    line-height: normal !important;
}

.Baildetail .psnotes,
.BondsManView .RequestDetail .psnotes {
    display: none;
}

/* Setting Page */
.SettingHeader .settings-header {
    background-color: #e9e9e9 !important;
}

/* Client & Management */
.BondsManView .ClientHistory [for="all"],
.BondsManView .CalenderAppointments [for="all"] {
    border: 1px solid var(--error);
    color: var(--error) !important;
}

.BondsManView .ClientHistory [for="all"].c-error,
.BondsManView .CalenderAppointments [for="all"].c-error {
    background-color: var(--error) !important;
    color: #fff !important;
}

.BondsManView .ClientHistory [for="high"] {
    border: 1px solid #007822;
    color: #007822 !important;
}

.BondsManView .ClientHistory [for="high"].c-error {
    background-color: #007822 !important;
    color: #fff !important;
}

.BondsManView .ClientHistory [for="reqular"] {
    border: 1px solid #9d6700;
    color: #9d6700 !important;
}

.BondsManView .ClientHistory [for="reqular"].c-error {
    background-color: #9d6700 !important;
    color: #fff !important;
}

.BondsManView .ClientHistory [for="new"] {
    border: 1px solid #272727;
    color: #272727 !important;
}

.BondsManView .ClientHistory [for="new"].c-error {
    background-color: #272727 !important;
    color: #fff !important;
}

/* Calender Appointment */

.BondsManView .CalenderAppointments [for="followUp"] {
    border: 1px solid #594431;
    color: #594431 !important;
}

.BondsManView .CalenderAppointments [for="followUp"].c-error {
    background-color: #594431 !important;
    color: #fff !important;
}

.BondsManView .CalenderAppointments [for="courtDate"].c-error {
    background-color: #ee407f !important;
    color: #fff !important;
}

.BondsManView .CalenderAppointments [for="courtDate"] {
    border: 1px solid #ee407f;
    color: #ee407f !important;
}

.BondsManView .CalenderAppointments [for="meeting"].c-error {
    background-color: #006278 !important;
    color: #fff !important;
}

.BondsManView .CalenderAppointments [for="meeting"] {
    border: 1px solid #006278;
    color: #006278 !important;
}

.antdRadioButtonGroup .ant-radio-button-wrapper {
    height: 2.75rem;
    line-height: 2.75rem;
    font-family: "Open Sans";
}

.antdRadioButtonGroup.calendar .calendar svg [stroke="#0D9AF2"] {
    stroke: #fff !important;
}

.antdRadioButtonGroup.calendar .list svg [fill="white"] {
    fill: #0d9af2 !important;
}

/* Bail Card */
.AdminIndex .CaseTypeCard .case-status-text {
    display: none !important;
}

.ClientCard .detail-card-user {
    flex: 0 0 calc(100% - 4rem);
    max-width: calc(100% - 4rem);
}
