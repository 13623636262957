/*Header Navbar Start*/
.header-container {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 998;
}
.header-container .navbar {
  --bs-navbar-padding-x: 3.75rem;
  --bs-navbar-padding-y: 0.938rem;
}
.header-container .navbar-brand img {
  width: auto;
  height: 3.75rem;
}
.btn-success.h-login-btn,
.menuToggle {
  --bs-btn-padding-y: 0.625rem;
}
.h-link {
  color: #f16d3d;
}
/* After Login */
.header-avatar {
  height: 2.875rem;
  width: 2.875rem;
  min-width: 2.875rem;
  min-height: 2.875rem;
  object-fit: cover;
}

.profileDropDown {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  right: 0;
  top: 4.125rem;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.875rem;
}

.profileDropDown .NavigationLink {
  display: flex;
  align-items: center;
}

.menubarRef .profileDropDown .NavigationLink .NavIconBG {
  height: 1.75rem;
  width: 1.75rem;
  display: grid;
  place-items: center;
}

.profileDropDown .NavigationLink .labelContainer {
  margin-left: 0.25rem;
  font-weight: 500;
}

.navLinkDirect.active {
  color: var(--primary) !important;
  position: relative;
}

/* .navLinkDirect.active::before {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: var(--primary);
    border-radius: 50%;
    left: -1rem;
    top: 35%;
} */

.need-blue-bullet {
  left: 1.5rem;
}

.need-blue-bullet:before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: var(--primary);
  border-radius: 50%;
  left: -1rem;
  top: 58%;
}

.downIcon {
  transition: all 0.25s ease 0s;
}

.downIcon svg {
  height: 0.8rem;
}

/* All Role Menubar */
.header-profile .profileDropDown {
  gap: 0.875rem;
}

.mobileDashboardList .NavigationLink {
  align-items: center;
  color: inherit;
  height: 3rem;
}

.mobileDashboardList .NavIconBG {
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  padding: 0.313rem;
}

.mobileDashboardList .NavigationLink .labelContainer {
  font-size: 0.875rem;
  margin-left: 0.625rem;
  font-weight: 600;
}

.mobileDashboardList .NavigationLink.active .NavIconBG svg [fill="black"],
.mobileDashboardList .NavigationLink.active .NavIconBG svg [fill="#030104"] {
  fill: #3aa61c;
}

.mobileDashboardList .NavigationLink.active .NavIconBG svg [stroke="black"],
.mobileDashboardList .NavigationLink.active .NavIconBG svg [stroke="#030104"] {
  stroke: #3aa61c;
}

.mobileDashboardList .NavigationLink.active .NavIconBG svg [stroke="white"],
.mobileDashboardList .NavigationLink.active .NavIconBG svg [fill="white"] {
  fill: transparent;
  stroke: transparent;
}

.mobileDashboardList .NavigationLink.active .labelContainer {
  color: #3aa61c;
}

.mobileDashboardList .NavigationLink.active {
  background-color: rgba(58, 166, 28, 0.1);
}

.mobileDashboardList .lengthFor {
  min-height: 1.75rem;
  width: 2.313rem;
  background-color: var(--primary);
  text-align: center;
  border-radius: 14px;
  line-height: 1.75rem;
}

.svgForIcon svg {
  height: 2.875rem;
}

@media (max-width: 991.98px) {
  .header-container .navbar {
    --bs-navbar-padding-x: 1rem;
  }

  .header-container .navbar {
    height: 70px;
  }

  /* Menubar Without Login */
  .mobileMenubar.menubarRef .profileDropDown {
    gap: 0.75rem;
  }

  .mobileMenubar .profileDropDown {
    grid-template-columns: repeat(1, 1fr);
  }

  .header-profile .profileDropDown {
    gap: 0.75rem;
  }

  /* Dashboard list */
  .mobileMenubar.mobileDashboardList .profileDropDown {
    max-height: calc(100vh - 5.75rem);
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .header-container .navbar-brand img {
    height: 2.5rem;
  }
  .btn-success.h-login-btn,
  .menuToggle {
    --bs-btn-padding-y: 0.5rem;
    font-size: 0.875rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .profileDropDown {
    row-gap: 0.5rem;
    grid-template-columns: repeat(1, 1fr);
    top: 3.5rem;
  }
}

@media (max-width: 575.98px) {
  .header-container .fs-6 {
    font-size: 0.75rem !important;
  }

  .btn-success.h-login-btn,
  .menuToggle {
    width: 1.875rem;
    height: 1.875rem;
    padding: 0 !important;
    line-height: 1.875rem;
  }

  .header-container .navbar-brand img {
    height: 1.875rem;
  }

  .header-container .afterLogin a.text-uppercase svg,
  .header-container .afterLogin .header-avatar {
    height: 2rem;
    min-height: 2rem;
    width: 2rem;
    min-width: 2rem;
  }

  .header-profile .profileDropDown {
    gap: 0.5rem;
  }

  .header-container .profileDropDown.navigateListMenubar {
    top: 3.75rem;
  }

  .header-container .navbar .navbar-brand img {
    height: 1.65rem;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .btn-success.h-login-btn,
  .menuToggle {
    height: 2.5rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .mobileMenubar .profileDropDown,
  .header-container .profileDropDown.navigateListMenubar {
    top: 4.125rem;
  }

  .mobileDashboardList .menuToggle {
    width: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mobileMenubar .profileDropDown,
  .header-container .profileDropDown.navigateListMenubar {
    top: 5.5rem;
  }
}

@media (min-width: 991px) {
  .header-container .profileDropDown.navigateListMenubar {
    top: 5.5rem;
  }

  .header-profile .profileDropDown .NavigationLink[data-image="My Cases"],
  .header-profile .profileDropDown .NavigationLink[data-image="Find A Bondsman"] {
    display: none !important;
  }
}
/*Header Navbar End*/

/*Main Home page Main Section Start*/
.main-section {
  background-image: url("../../Assets/Images/Banner/main-section-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  min-height: 46.25rem;
  padding-top: 5.625rem;
  padding-bottom: 5rem;
}
.main-sec-box {
  width: 12.5rem;
  max-width: 100%;
  min-height: 11.75rem;
  border-color: #dcdcdc;
  border-radius: 0.313rem;
  margin: 0 auto;
  transition: all 0.15s ease-in-out;
}

.main-sec-box svg[width="78"][height="78"] {
  width: 4.875rem;
  height: 4.875rem;
}
@media (min-width: 1200px) {
  .main-section .container {
    max-width: 82.5rem;
  }
}
@media (max-width: 1199.98px) {
  .main-section .display-3 {
    font-size: 3.75rem;
    text-shadow: 2px 2px #ffffff;
  }
}
@media (max-width: 991.98px) {
  .main-section .display-3 {
    font-size: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .main-section {
    padding-top: 3.625rem;
    padding-bottom: 3rem;
  }
  .main-section .display-3 {
    font-size: 2rem;
  }
}
@media (max-width: 575.98px) {
  .main-section {
    padding-top: 2.625rem;
    padding-bottom: 2rem;
    min-height: 40rem;
  }
  .main-section .display-3 {
    font-size: 1.5rem;
  }
  .main-section .fs-4 {
    font-size: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .main-sec-box {
    min-height: 10rem;
    width: 10rem;
  }
  .main-sec-box svg[width="78"][height="78"] {
    width: 2.875rem;
    height: 2.875rem;
  }
  .main-sec-box .fs-6 {
    font-size: 0.75rem !important;
  }
  .main-sec-box .card-body {
    padding: 1rem !important;
    padding-top: 1.5rem !important;
  }
}
/*Main Home page Main Section End*/

/*Why Choose Section Start*/
.why-choose-card {
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.why-choose-card .card-body {
  padding: 1.25rem;
}
.why-choose-card .circle-box {
  width: 4.25rem;
  height: 4.25rem;
  flex: 0 0 4.25rem;
}
.why-choose-card .circle-box svg {
  height: 2.25rem;
}
.fs-50 {
  font-size: 3.125rem;
}
.bondsman-card .circle-box {
  background-color: rgba(0, 85, 185, 0.1);
}
.bondsman-card h3 {
  color: #0055b9;
}
.success-card .circle-box {
  background-color: rgba(0, 120, 34, 0.1);
}
.success-card h3 {
  color: rgba(0, 120, 34, 1);
}
.client-card .circle-box {
  background-color: rgba(157, 103, 0, 0.1);
}
.client-card h3 {
  color: #9d6700;
}
.technical-card .circle-box {
  background-color: rgba(217, 65, 0, 0.1);
}
.technical-card h3 {
  color: rgba(217, 65, 0, 1);
}
@media (max-width: 575.98px) {
  .wc-section .fs-50 {
    font-size: 2.125rem;
  }
  .wc-section .fs-18 {
    font-size: 1rem;
  }
  .why-choose-card .circle-box {
    width: 3.25rem;
    height: 3.25rem;
    flex: 0 0 3.25rem;
  }
}
/*Why Choose Section End*/

/*Services Provide Section Start*/
.service-card {
  width: 28.75rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.service-card .image-body::before {
  content: "";
  display: block;
  padding-top: 69.57%; /* This is the aspect ratio (320 / 460) expressed as a percentage */
}
/*Services Provide Section End*/

/* Find A Bondsman */
.Find-A-Bondsman .antdPostCode {
  height: 5rem;
}

.Find-A-Bondsman .antdPostCode .ant-select-selector {
  border: transparent !important;
  box-shadow: none !important;
  padding: 0 2.5rem;
}

.Find-A-Bondsman .ant-select .ant-select-arrow {
  inset-inline-end: unset;
  inset-inline-start: 1rem;
}

.Find-A-Bondsman .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-start: 18px;
  padding-inline-end: 0;
}

.Find-A-Bondsman .ant-select-single .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 49px;
}

.Find-A-Bondsman .ant-select .ant-select-arrow svg [fill="#3AA61C"] {
  fill: var(--error) !important;
}

.Find-A-Bondsman .subZipCode {
  flex: 0 0 auto;
  position: absolute;
  right: 1rem;
}

.Find-A-Bondsman .antdPostCode .ant-select-selection-item {
  inset-inline-start: 18px;
}

.ant-select-dropdown .ant-select-item {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Open Sans";
  min-height: 2rem;
  line-height: 2rem;
  margin-bottom: 0.25rem;
}

.Find-A-Bondsman .ant-select .ant-select-clear {
  inset-inline-end: unset;
  inset-inline-start: 1rem;
}

.Find-A-Bondsman .ant-select .ant-select-clear svg,
.Find-A-Bondsman .ant-select .ant-select-clear {
  width: 16px;
  height: 16px;
}

.Find-A-Bondsman .ant-select .ant-select-clear svg {
  position: relative;
  top: -1px;
}
/*Testimonials Section Start*/
.testimonials-img {
  width: 100%;
}
.testimonials-img::before {
  content: "";
  display: block;
  padding-top: 73.15%; /* This is the aspect ratio (320 / 460) expressed as a percentage */
}
svg[width="100"][height="100"] {
  height: 6.25rem;
}
.testimonials-text {
  max-height: 33.313rem;
}
@media (max-width: 1199.98px) {
  .testimonials-text {
    max-height: 21.375rem;
  }
}
@media (max-width: 991.98px) {
  .comma_sign.top-50 {
    top: 0 !important;
    left: 50%;
  }
}
@media (max-width: 575.98px) {
  svg.comma_sign[width="100"][height="100"] {
    height: 4.25rem;
  }
  .testimonials-section .fs-5 {
    font-size: 1rem !important;
  }
}
/*Testimonials Section End*/

/*Review the information*/
@media (max-width: 767.98px) {
  .reviewCard {
    font-size: 0.875rem;
  }
  .reviewCard .fs-4 {
    font-size: 1.25rem !important;
  }
  .reviewCard .fs-6 {
    font-size: 0.875rem !important;
  }
  .reviewCard .fs-5 {
    font-size: 1rem !important;
  }
  .reviewCard .mb-30 {
    margin-bottom: 1.5rem;
  }
  .reviewCard .custom__checkbox .form-check-input {
    width: 1.15rem;
    height: 1.15rem;
  }
}

@media (max-width: 575.98px) {
  .customSwiper .swiper-button-next {
    right: 0.25rem !important;
  }

  .customSwiper .swiper-button-prev {
    left: 0.25rem !important;
  }

  .customSwiper .swiper-button-next,
  .customSwiper .swiper-button-prev {
    width: 1.4rem;
    height: 1.4rem;
  }

  .customSwiper .swiper-button-next:after,
  .customSwiper .swiper-button-prev:after {
    font-size: 10px;
    position: relative;
    top: 1px;
  }
}
/*Review the information*/

/*Bail Request Submit Page*/
.process-card {
  border-radius: 5px;
  background: #f6f6f6;
}
.process-card svg[width="90"][height="90"] {
  width: 5.625rem;
  height: 5.625rem;
  flex: 0 0 5.625rem;
}
.checkmarkIcon {
  width: 12.5rem;
  height: 12.5rem;
}
@media (min-width: 768px) {
  .process-card > .card-body {
    padding: 1.875rem 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .checkmarkIcon {
    width: 5.5rem;
    height: 5.5rem;
    margin-bottom: 1.5rem !important;
  }
  .process-card svg[width="90"][height="90"] {
    width: 3rem;
    height: 3rem;
    flex: 0 0 3rem;
  }
}
/*Bail Request Submit Page*/

/*Footer Section Start*/
.form-control {
  border-radius: 0.5rem !important;
}

.footer-section {
  background-image: url("../../Assets/Images/footer-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 12.5rem;
}
.footer-section:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.9);
}

.ci-circle {
  width: 4.25rem;
  height: 4.25rem;
  flex: 0 0 4.25rem;
}

.ci-link,
.ci-link:hover {
  color: #141215;
}

.contact-card {
  width: 41.25rem;
  margin-top: -17.5rem;
}
.contact-card > .card-body {
  padding: 3.75rem 3.125rem;
}
.page-form textarea.form-control.form-control-lg,
.page-form input.form-control.form-control-lg {
  color: #000000;
  font-size: 1rem;
}
.page-form input.form-control.form-control-lg {
  min-height: calc(1.875rem + 1rem + calc(var(--bs-border-width) * 2));
}
.page-form .form-control::-moz-placeholder {
  color: #9b9b9b;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Open Sans";
}
.page-form .form-control::placeholder {
  color: #9b9b9b;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Open Sans";
}
.page-form .form-control:focus {
  border-color: #0d9af2;
  box-shadow: none;
}
.copyright-div {
  border-top: 1px solid #0d9af2;
  margin-bottom: -5rem;
  /* margin-top: -3.75rem; */
  margin-top: 3.75rem;
}

.copyright-text {
  color: #4e4637;
}

.copyright-text a:hover {
  color: #4e4637;
}
@media (max-width: 991.98px) {
  .footer-section.page-section {
    padding-bottom: 0;
  }

  .copyright-div {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-section,
  .contact-card {
    margin-top: 0;
  }
}
@media (max-width: 575.98px) {
  .contact-card > .card-body {
    padding: 2rem 1.5rem;
  }
  .footer-section .mb-5 {
    margin-bottom: 2rem !important;
  }
  .ci-circle {
    width: 3.25rem;
    height: 3.25rem;
    flex: 0 0 3.25rem;
  }
  .footer-section .fs-18 {
    font-size: 1rem;
  }
  .footer-section .fs-4 {
    font-size: 1.25rem !important;
  }
}

/* Update Footer Selected Page */
.FooterUpdate .footer-section .col-md-7 {
  display: none !important;
}

.FooterUpdate .footer-section .contactInfo,
.FooterUpdate .footer-section .copyRight {
  width: 100% !important;
}

.FooterUpdate .footer-section .copyright-div {
  margin-top: 0;
  display: flex;
  gap: 2rem;
}

.FooterUpdate .footer-section .copyright-text {
  margin-bottom: 0 !important;
}

.FooterUpdate .contactInfo .list-unstyled {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.FooterUpdate .contactInfo .list-unstyled li {
  /* margin-right: 0 !important; */
  margin-bottom: 1.5rem !important;
}

.FooterUpdate .footer-section.page-section {
  margin-top: 0 !important;
}

.FooterUpdate .contactInfo .list-unstyled li > div {
  align-items: start !important;
}

.FooterUpdate.RemoveFooter .footer-section {
  display: none;
}

@media (max-width: 767.98px) {
  .FooterUpdate .footer-section .copyright-div {
    display: block;
  }

  .FooterUpdate .footer-section.page-section .page-container > .mb-5 {
    margin-bottom: 0.5rem !important;
  }

  .FooterUpdate .contactInfo .list-unstyled {
    margin-bottom: 0 !important;
  }

  .FooterUpdate .contactInfo .list-unstyled {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .FooterUpdate .ci-circle {
    width: 3.25rem;
    height: 3.25rem;
    flex: 0 0 3.25rem;
  }

  .FooterUpdate .fs-18 {
    font-size: 1rem;
  }

  .FooterUpdate .contactInfo > .mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .FooterUpdate .footer-section.page-section {
    padding-top: 2.5rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .FooterUpdate .contactInfo .list-unstyled {
    grid-template-columns: repeat(2, 1fr);
  }

  .FooterUpdate .footer-section.page-section .page-container > .mb-5 {
    margin-bottom: 0.5rem !important;
  }
}

@media (max-width: 575.98px) {
  .FooterUpdate .contactInfo .list-unstyled {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* @media (min-width: 1024.98px) {
    .ClientView {
        min-height: calc(100vh - 80px);
        background-color: var(--light);
    }

    .ClientView > div {
        height: 100% !important;
    }
} */

.ClientView {
  min-height: calc(100vh - 80px);
  background-color: var(--light);
}

.ClientView > div {
  height: 100% !important;
}
/*Footer Section End*/

/* PDF Download  - Payment Receipt*/
.download-header {
  height: 90px;
}

/* Request Detail Client */
.RequestDetail .requestButton {
  height: 2.75rem;
  width: 2.75rem;
}

.RequestDetail .requestButton svg {
  height: 1.5rem;
}

@media (max-width: 575.98px) {
  .BidUser .fullname,
  .BondsmanCard-Amount .Amount {
    font-size: 1rem;
  }

  .BondsmanCard svg {
    height: 1.2rem;
  }

  .RequestDetail .requestButton {
    height: 2.5rem;
    width: 2.5rem;
  }

  /* Bid Detail */
  .BailBondsmanDetailCard {
    text-align: center;
  }
}

.pointer-event-disable * {
  pointer-events: none;
}

/* Contact Us */
.contact-us-footer .footer-section {
  display: none;
}

.contact-us-footer .ClientView {
  background: #fff;
}

/* BG WHITE */
.body-bg-white .ClientView {
  background: #fff;
}

.body-bg-white .ViewIndex {
  background: #fff !important;
}
