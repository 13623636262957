/*Common CSS Start*/
@media (min-width: 768px) {
    html {
        font-size: 76%;
    }
}
@media (min-width: 1400px) {
    html {
        font-size: 76%;
    }
}
@media (min-width: 1600px) {
    html {
        font-size: 100%;
    }
}

html,
body,
#root,
.ant-app {
    font-family: "Open Sans", sans-serif;
}

/* Color scheme of the project Approved Date: Friday, 27 Sep 2023 */
:root {
    --primary: #0d9af2;
    --secondary: #05f3db;
    --brown: #bf9e61;
    --success: #3aa61c;
    --green: #007822;
    --error: #f16d3d;
    --light: #f2f2f2;
    --grey: #898989;
    --red: rgba(255, 0, 0, 1);
    --bs-info-rgb: 13, 154, 242;
    --b-shadow: 0px 0px 20px 0px #0000001a;
    --pink: #ee407f;
}

/* Fixing CSS Default CSS*/
p {
    margin-bottom: 0;
}

.c-white {
    background-color: #fff;
}

.c-grey {
    background-color: var(--grey) !important;
}

.c-light {
    background-color: var(--light) !important;
}

.c-error {
    background-color: var(--error) !important;
}

.c-warning {
    background-color: var(--red) !important;
}

.c-primary {
    background-color: var(--primary) !important;
}

.c-success {
    background-color: var(--success) !important;
}

.c-orange-10 {
    background: rgba(241, 109, 61, 0.1);
}

.txt-dark,
.text-dark {
    color: #000000 !important;
}

.txt-dark-2 {
    color: #333333;
}

.txt-error {
    color: var(--error) !important;
}

.txt-white {
    color: var(--light) !important;
}

.txt-grey {
    color: var(--grey) !important;
}

.txt-primary {
    color: var(--primary) !important;
}

.txt-success {
    color: var(--success) !important;
}

.txt-green {
    color: var(--green) !important;
}

.txt-brown {
    color: var(--brown) !important;
}

.txt-warning {
    color: var(--red) !important;
}

.txt-light {
    color: var(--light) !important;
}

.txt-hover-error:hover {
    color: var(--error) !important;
}

.txt-hover-white {
    color: var(--light) !important;
}

.txt-hover-grey {
    color: var(--grey) !important;
}

.txt-hover-primary {
    color: var(--primary) !important;
}

.txt-hover-success {
    color: var(--success) !important;
}

.txt-hover-brown {
    color: var(--brown) !important;
}

.txt-hover-warning {
    color: var(--red) !important;
}

.txt-pink {
    color: var(--pink) !important;
}

.terms-light {
    color: #646c74 !important;
}

.txt-BF9E61 {
    color: #bf9e61 !important;
}
/* Fixing CSS Default CSS*/
/* Font Family */
.ff {
    font-family: "Open Sans";
}

.ff_rg {
    font-weight: 400 !important;
}

.ff_md,
.fw-medium {
    font-weight: 600 !important;
}

.ff_bd {
    font-weight: 700 !important;
}

/* Font Size */
.f10 {
    font-size: 0.625rem;
}

.f12 {
    font-size: 0.75rem;
}

.f13 {
    font-size: 0.813rem;
}

.f14 {
    font-size: 0.875rem;
}

.f16 {
    font-size: 1rem;
}

.f18 {
    font-size: 1.125rem;
}

.f20 {
    font-size: 1.25rem;
}

.f22 {
    font-size: 1.375rem;
}

.f24 {
    font-size: 1.5rem;
}

.f28 {
    font-size: 1.75rem;
}

.f30 {
    font-size: 1.875rem;
}

.f32 {
    font-size: 2rem;
}

.f40 {
    font-size: 2.5rem;
}

.line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-2 {
    -webkit-line-clamp: 2;
}

.line-3 {
    -webkit-line-clamp: 3;
}

/* Comman CSS */
/* Height - Min Height */
.vh-100 {
    height: 100vh;
}

.h24 {
    height: 1.5rem;
}

.h30 {
    height: 1.875rem;
}

.h36 {
    height: 2.25rem;
}

.h38 {
    height: 2.375rem;
}

.h42 {
    height: 2.625rem;
}

.h46 {
    height: 2.75rem;
}

.btn.h46 {
    line-height: 1.75rem;
}

.h48 {
    height: 3rem;
}

.h50 {
    height: 3.125rem;
}

.h70 {
    height: 4.375rem;
}

.lh50 {
    line-height: 3.125rem !important;
}

.line-height-normal {
    line-height: normal;
}

hr {
    border-top-color: #dcdcdc;
    opacity: 0.5;
}

/* Width - Min Width */
.min-fit-content {
    min-width: fit-content;
}
/* Comman CSS End*/

/* Cursor */
.cursor-pointer-default {
    cursor: default !important;
}

.cursor-pointer {
    cursor: pointer;
}
/* Scroll */
::-webkit-scrollbar {
    border-radius: 0.375rem;
    width: 0.375rem;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 0.375rem;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 var(--light);
}

/* Opacity */
.opacity-0 {
    opacity: 0%;
}

.opacity-25 {
    opacity: 25%;
}

.opacity-50 {
    opacity: 50%;
}

.opacity-75 {
    opacity: 75%;
}

.opacity-100 {
    opacity: 100%;
}

/* Input Field */
.input-box-shadow {
    box-shadow: 0 0.125rem 0.375rem 0 #0187cf33;
}

.focus-border:focus {
    border: 0.063rem solid var(--primary);
    box-shadow: none;
}

.rounded-8 {
    border-radius: 0.5rem;
}

.replyCss {
    border-radius: 10px 10px 10px 0;
}

/* custom Padding */
.px-12 {
    padding: 0 12px !important;
}

.b-shadow {
    box-shadow: var(--b-shadow) !important;
}

.spinner-border {
    border-color: var(--color);
    border-right-color: transparent;
}

.bg-danger {
    background-color: var(--error) !important;
}

.min-width-120 {
    min-width: 7.5rem !important;
}

/* Border Radius */
.border-s {
    border-radius: 0.313rem !important;
}

.border-m {
    border-radius: 0.625rem !important;
}

.border-1 {
    border-width: 0.063rem;
    border-style: solid;
}

.border-5 {
    border-width: 0.094rem;
    border-style: solid;
}

.border-2 {
    border-width: 0.125rem;
    border-style: solid;
}

.b-error {
    border-color: var(--error);
}

.b-light-grey {
    border-color: rgba(220, 220, 220, 1);
}

.top-padding {
    padding: 2.5rem 0 !important;
}

.border-error {
    border-color: var(--error) !important;
}

/* SVG Color */
.svg_primary * {
    fill: var(--primary) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/*Common CSS Start*/
.mb-50 {
    margin-bottom: 3.125rem;
}
.mt-40 {
    margin-top: 2.5rem;
}
.mb-40 {
    margin-bottom: 2.5rem;
}
svg * {
    transition: all 0.15s ease-in-out;
}
.page-section {
    padding: 5rem 0;
}
.section-title h2 {
    font-size: 2.625rem;
}
.section-title h2 + p {
    color: #090909;
    margin-top: 2rem;
    font-size: 1.125rem;
}
.fs-40 {
    font-size: 2.5rem;
}
.fs-50 {
    font-size: 3.125rem;
}
.fs-18 {
    font-size: 1.125rem;
}
.fs-14 {
    font-size: 0.875rem;
}
.section-bg-gray {
    background: #f2f2f2;
}
.rounded-10 {
    border-radius: 0.625rem !important;
}
.card-shadow {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.btn-lg.theme-btn {
    --bs-btn-font-size: 1rem;
    text-transform: uppercase;
    font-family: "Open Sans";
    font-weight: 600;
    border-radius: 0.375rem;
    --bs-btn-padding-y: 0.625rem;
}
.flex__1 {
    flex: 1;
}
@media (min-width: 1400px) {
    .container.page-container {
        max-width: 92.75rem;
    }
}
@media (max-width: 575.98px) {
    .page-section {
        padding: 3rem 0;
    }
    .section-title h2 {
        font-size: 1.5rem;
    }
    .section-title h2 + p {
        margin-top: 1rem;
        font-size: 0.875rem;
    }
}
/*Common CSS End*/

/*Common CSS*/
@media (min-width: 1200px) {
    .subpage-container {
        max-width: 120rem;
        padding-left: 3.75rem;
        padding-right: 3.75rem;
    }
    .subpage-container-1400 {
        max-width: 89rem;
    }
}
.text-orange {
    color: #f16d3d;
}
.mb-30 {
    margin-bottom: 1.875rem;
}

@media (min-width: 768px) {
    .card-body.p-40 {
        padding: 2.5rem;
    }
    .card-body.p-60 {
        padding: 3.75rem;
    }
}
.custom__checkbox .form-check-input {
    width: 1.375rem;
    height: 1.375rem;
    margin-top: 0.063rem;
}
.custom__checkbox .form-check-input:checked {
    background-color: #0d9af2;
    border-color: #0d9af2;
}
@media (max-width: 767.98px) {
    .font-size-mobile .fs-5 {
        font-size: 1rem !important;
    }
    .font-size-mobile .fs-6 {
        font-size: 0.875rem !important;
    }
}
/*Common CSS*/
