/***** Sign Up / Login Page CSS Start *****/
.signup-img-column {
    background-image: url("../Images/sign-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.signup-img-column > div {
    background-color: #0d9af2;
    padding: 0.875rem;
}
.signup-img-column > div h1 {
    font-size: 3.5rem;
}
.signup_flex {
    width: 30rem;
    flex: 1;
}
.sign-card {
    border-radius: 1.25rem;
    background: #f2f2f2;
}
.sign-card > .card-body {
    padding: 3.125rem 2.5rem;
}
.form-wrapper {
    flex: 1;
}

.btn-light.signup-check-btn {
    --bs-btn-color: #000;
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #ffffff;
    text-align: center;
}
.btn-check:checked + .btn.signup-check-btn {
    color: #ffffff;
    background-color: #0d9af2;
    border-color: #0d9af2;
}
.btn-check:checked + .btn.signup-check-btn .exclamation_sign svg [fill="black"],
.btn-check:checked + .btn.signup-check-btn svg [fill="#0D9AF2"] {
    fill: #ffffff;
}
.signup-check-btn > svg[width="78"][height="78"] {
    width: 4.875rem;
    height: 4.875rem;
    margin: 0 auto;
    margin-bottom: 1.25rem;
}
.exclamation_sign {
    position: absolute !important;
    right: 0.375rem;
    top: 0.375rem;
    line-height: 1;
}

.exclamation_sign svg {
    height: 1rem;
}
.red__link {
    color: #f16d3d;
}
.countryCode {
    border-radius: 0.25rem;
    background: #272727;
    color: #ffffff;
    font-size: 1rem;
    height: calc(100% - 0.5rem);
    top: 0.25rem;
    left: 0.25rem;
    padding: 0.5rem 0.813rem;
    min-width: 2.5rem;
    text-align: center;
}

.countryCode.percantageTax {
    right: 0.25rem !important;
    left: auto;
}

.profileUploadBox {
    width: 6.25rem;
    height: 6.25rem;
    border: 1px solid #cccccc;
    background-color: #f7f7f7;
}
.profileUploadBox img.object-fit-cover {
    background-color: #f7f7f7;
}
.placeholderProfile {
    left: 0;
    width: 100%;
    bottom: 0;
    height: 5.313rem;
    max-height: 100%;
}
.pl-3_5 {
    padding-left: 3.5rem;
}
.pe-3_5 {
    padding-right: 3.5rem;
}
.upload-pro-btn svg[width="35"][height="35"] {
    height: 2.188rem;
}
.formik-input-icon {
    position: absolute;
    top: 0.775rem;
    right: 10px;
}

@media (max-width: 575.98px) {
    .sign-card > .card-body {
        padding: 2.5rem 1rem;
    }
    .btn-light.signup-check-btn {
        width: 11.875rem;
        max-width: 100%;
        margin: 0 auto;
        --bs-btn-font-size: 0.875rem;
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .signup-check-btn > svg[width="78"][height="78"] {
        width: 3.875rem;
        height: 3.875rem;
        margin-bottom: 1rem;
    }
}

/***** Sign Up / Login Page CSS End *****/
